import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import logo from '../images/logo.svg';

const Footer = props => (
  <div className="footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="footer">
            <img src={logo} alt={props.data.site.siteMetadata.title} />
            <h3 className="footer-title">{props.data.site.siteMetadata.title}</h3>
            <ul className="footer-menu">
              <li className="copyright">
                © {props.data.site.siteMetadata.title} {new Date().getFullYear()} - {props.data.site.siteMetadata.contact.direitos}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query DadosWebsite {
        site {
          siteMetadata {
            title
            contact {
              direitos
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
