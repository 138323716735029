import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

const SubFooter = props => (
  <div className="sub-footer-strip">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="sub-footer">
            <ul>
              <li>
                <strong>Design &amp; Desenvolvimento: </strong>
                <a href={props.data.site.siteMetadata.designndevelopment.website}>{props.data.site.siteMetadata.designndevelopment.studio}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query SiteMetadataFooterQuery {
        site {
          siteMetadata {
            contact {
              email
              phone
            }
            designndevelopment {
              studio
              website
            }
          }
        }
      }
    `}
    render={data => <SubFooter data={data} />}
  />
);
