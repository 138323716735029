import styled from 'styled-components';


const theme = {
  font: {
    primary: `'HelveticaNeueLTStd-ThinExtended', serif`,
    secondary: `'HelveticaNeueLTStd-LightCondensed', serif`,
  },
  font_size: {
    small: 'font-size: 20px; line-height: 30px',
    regular: 'font-size: 24px; line-height: 32px',
    large: 'font-size: 30px; line-height: 40px',
    larger: 'font-size: 36px; line-height: 48px',
    xlarge: 'font-size: 48px; line-height: 56px',
  },
  color: {
    white: {
      regular: '#FFFFFF',
      dark: '#F6F6F6',
    },
    black: {
      lighter: '#ABA8AF',
      light: '#564F62',
      regular: '#211E26',
    },
    primary: ' #e54d2d',
    secondary: '#9b878a',
    marrom: ' #373131 ',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
  height: {
    headerHeight: '50px',
    headerHeightMd: '100px',
    headerHeightLg: '140px',
  },
};

export const MenuIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={props.fill} className={props.class} width="24" height="24" viewBox="0 0 24 24" stroke={props.stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg>
);

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  height: 100%;

  @media (min-width: ${theme.screen.xs}) {
    max-width: 540px;
  }

  @media (min-width: ${theme.screen.sm}) {
    max-width: 720px;
  }

  @media (min-width: ${theme.screen.md}) {
    max-width: 990px;
  }

  @media (min-width: ${theme.screen.lg}) {
    max-width: 1200px;
  }

  ${props =>
    props.fluid &&
    `
    max-width: 1200px !important;
  `};
`;

export const Nav = styled.nav`
  padding: 7px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  background-color: ${theme.color.white.regular};
  @media (min-width: ${theme.screen.md}) {
    height: 140px;
    z-index:2;
  }
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;


export const LogoWrapper = styled.div`
  height: 20%;
  border-bottom: 1px solid ${theme.color.secondary}
`;

export const NavListWrapper = styled.div`
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content;
  ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      `
        flex-direction: column;
        margin-top: 1em;
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;

        > ${NavItem} {
          margin: 0;
        }
      `};
  }
`;

export const NavItem = styled.li`
  margin: 0;
  font-family: ${theme.font.primary};
  ${theme.font_size.small};
  color: ${theme.color.secondary};
  text-align: center;
  a {
    text-decoration: none;
    color: ${theme.color.secondary};
  }

  &.active, &:hover {
    a {
      color: ${theme.color.primary};
      text-decoration: none;
    }
  }
    @media(min-width: ${theme.screen.md}){
      &:after{
        content:"･";
        margin:0.5em;
      }
      &:last-of-type {
        margin: 0;
        &:after {
          content:"";
        }
    }
`;

export const MobileMenu = styled.div`
  width: 100%;
  background: ${theme.color.marrom};
  height: 100vh;
  position: relative;
  top: -98px;
  @media (min-width: ${theme.screen.sm}){
    top: -140px;
  }
`;

export const Mobile = styled.div`
  display: none;

  @media (max-width: ${theme.screen.md}) {
    display: block;
  }

  ${props =>
    props.hide &&
    `
    display: block;

    @media (max-width: ${theme.screen.md}) {
      display: none;
    }
  `}
`;
