import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import logo from '../images/logo.svg';
import logoReduzida from '../images/logo-reduzida.png';

import {
  MenuIcon,
  Nav,
  NavItem,
  StyledContainer,
  NavListWrapper,
  LogoWrapper,
  MobileMenu,
  Mobile,
  Container,
} from './HeaderStyle';

const NAV_ITEMS = ['Home', 'Quem Somos', 'Nosso Time', 'Áreas de Atuação', 'Contato'];

const removeAccents = require('remove-accents');


const Navbar = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };


  const getNavAnchorLink = item => (
    <AnchorLink offset="70" href={`#${removeAccents(item).toLowerCase().replace(/\s/g, '')}`} onClick={toggleMobileMenu}>
      {item}
    </AnchorLink>
  );

  const getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <Scrollspy
        items={NAV_ITEMS.map(item => removeAccents(item).toLowerCase().replace(/\s/g, ''))}
        currentClassName="active"
        mobile={mobile}
        offset={-64}
      >
        {NAV_ITEMS.map(navItem => (
          <NavItem key={removeAccents(navItem).toLowerCase().replace(/\s/g, '')}>{getNavAnchorLink(navItem)}</NavItem>
        ))}
      </Scrollspy>
    </NavListWrapper>
  );

  return (
    <Nav {...props}>
      <StyledContainer>
      <AnchorLink offset="100" href="#home">
        <img src={logo} alt="Mandetta Advogados" className="logo" style={{ maxWidth: '170px', margin: '10px' }} />
      </AnchorLink>
        <Mobile>
          <button type="button" onClick={toggleMobileMenu} style={{ outline: 'none', zIndex: '1', position: 'relative', background: 'transparent', border: 'none' }}>
            <MenuIcon fill="#e54d2d" stroke="#e54d2d" />
          </button>
        </Mobile>
        <Mobile hide>{getNavList({})}</Mobile>
      </StyledContainer>
      <Mobile>
        {mobileMenuOpen && (
          <MobileMenu>
            <LogoWrapper>
              <img src={logo} alt="Mandetta Advogados" className="logo-mobile" />
            </LogoWrapper>
            <Container>{getNavList({ mobile: true })}</Container>
            <img src={logoReduzida} alt="Mandetta Advogados" style={{ height: '40px' }} />
          </MobileMenu>
        )}
      </Mobile>
    </Nav>
  );
};

export default Navbar;
